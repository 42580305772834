import router from '@/router'
import axios from '../config/api'
import { deepCopy } from '@/utils/utils'
const campaignType = process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'

const state = () => ({
  products: [],
  additionalProduct: [],
  stocks: null,

  selectGroup: null,
  selectColor: null,
  selectModel: null,
  selectSKU: null,

  isError: false,
  isLoading: false,
  isLoaded: false,
  isIncoming: false,
})

// getters
const getters = {
  getProductLevel1: state => {
    return state.selectGroup ?? []
  },

  getProductLevel2: state => {
    return (
      state.selectGroup?.product_group?.map(item => {
        return {
          ...item,
          disabled: item.model?.every(model => {
            return model.data?.every(item => !item.active)
          }),
        }
      }) ?? []
    )
  },

  getProductLevel3: state => {
    return (
      state.selectColor?.model?.map(model => {
        return {
          ...model,
          name: model.model_name,
          disabled: model.data?.every(item => !item.active),
        }
      }) ?? []
    )
  },

  getProductLevel4: state => {
    return (
      state.selectModel?.data?.map(item => {
        return {
          ...item,
          disabled: !item.active,
        }
      }) ?? []
    )
  },

  getModels: state => {
    return state.selectGroup?.product_group ?? []
    // (
    //   state?.products?.map(product => {
    //     return {
    //       ...product,
    //       product_group: product.product_group.map(group => {
    //         return {
    //           ...group,
    //           model: group.model.map(model => {
    //             const prices = model.data.map(item => item.price)
    //             const startPrice = Math.min(...prices)
    //             return {
    //               ...model,
    //               start_price: startPrice,
    //             }
    //           }),
    //         }
    //       }),
    //     }
    //   }) ?? []
    // )
  },

  getColors: state => state.selectModel?.model,

  getStorages: state => {
    return (
      state.selectColor?.data ?? state.selectModel?.model?.[0]?.data
    )
  },

  getProductGroups: state => {
    return (
      state?.products?.map(product => {
        return {
          ...product,
        }
      }) ?? []
    )
  },

  getProductColors: state => {
    return (
      state.selectGroup?.model?.map(model => {
        return {
          ...model,
        }
      }) ?? []
    )
  },

  getSoldOut: state => {
    let countActive = 0
    // model > color > sku
    // for (const p of state?.products) {
    //   for (const m of p?.model) {
    //     for (const c of m?.data) {
    //       if (c.active === true) {
    //         countActive++
    //       }
    //     }
    //   }
    // }
    for (const level1 of state?.products) {
      for (const level2 of level1?.product_group) {
        for (const level3 of level2?.model) {
          for (const c of level3?.data) {
            if (c.active === true) {
              countActive++
            }
          }
        }
      }
    }
    return !(countActive > 0)

    // const active = state?.products[0]?.model[0]?.data?.filter(
    //   items => {
    //     return items.active
    //   },
    // )
    // return active?.length <= 0 ? true : false
  },

  getBundles: state => {
    const getStorages = getters.getStorages?.[0]
    const items =
      state.selectSKU?.bundle_product ?? getStorages?.bundle_product
    return items?.filter(
      item =>
        item.type !== 'warranty' &&
        item.type !== 'delivery' &&
        item.type !== 'express' &&
        item.type !== 'accessory_bundle',
    )
  },

  getFreebie: state => {
    const getStorages = getters.getStorages?.[0]
    const items =
      state.selectSKU?.bundle_product ?? getStorages?.bundle_product
    return items?.filter(item => item.type === 'free')
  },

  //  free_handraiser for campaign samsung
  getHandraiserFreebies: state => {
    const getStorages = getters.getStorages?.[0]
    const items =
      state.selectSKU?.bundle_product ?? getStorages?.bundle_product
    return items?.filter(item => item.type === 'free_handraiser')
  },

  getAdditionalProduct: state => {
    const { additionalProduct } = state
    let items = []
    additionalProduct.map(a => {
      a?.products?.map(i => items.push(i))
    })
    return items?.filter(
      item =>
        item.type !== 'warranty' &&
        item.type !== 'delivery' &&
        item.type !== 'express' &&
        item.type !== 'accessory_bundle',
    )
  },

  getBundleDelivery: state => {
    const items = state.selectSKU?.bundle_product || []
    return items.filter(
      item => item.type === 'delivery' || item.type === 'express',
    )
  },

  getAppleCare: state => {
    const items = state.selectSKU?.bundle_product || []
    return items?.filter(item => item.type === 'warranty')?.[0]
  },

  getAccessoryBundles: state => {
    const items = state.selectSKU?.bundle_product || []
    return items?.filter(item => item.type === 'accessory_bundle')
  },

  getProductFamilies: state => {
    return state.products ?? []
  },
}

// actions
const actions = {
  async getStocks({ commit }, { slug }) {
    commit('setLoading', true)

    let endpoint = `/${campaignType}/stock?campaign=${slug}`

    if (router.currentRoute.query.admin_quota) {
      let admin_quota =
        router.currentRoute.query.admin_quota.toLowerCase() === 'true'
          ? 1
          : 0
      endpoint = `${endpoint}&admin_quota=${admin_quota}`
    }

    try {
      const result = await axios.get(endpoint)
      commit('setStocks', result.data.data)
      commit('setLoading', false)
    } catch {
      commit('setLoading', false)
      throw new Error('Error')
    }
  },

  async updateStatusSKU({ commit, state }) {
    const mainProduct = deepCopy(state.products)
    const additionalProducts = deepCopy(state.additionalProduct)
    if (mainProduct?.length === 0) {
      return
    }

    // group > model > color > sku
    // for (const group of mainProduct) {
    //   for (const model of group?.product_group) {
    //     for (const color of model?.model) {
    //       for (const capacity of color?.data) {
    //         // update status sku of product
    //         capacity.active = state.stocks?.[capacity.sku] ?? false

    //         // update status sku of bundle
    //         for (const bundle of capacity?.bundle_product) {
    //           bundle.active = state.stocks?.[bundle.sku] ?? false
    //         }

    //         // update status sku of bundle
    //         for (const variant of capacity?.variant_product) {
    //           variant.active = state.stocks?.[variant.sku] ?? false
    //         }
    //       }
    //     }
    //   }
    // }

    // group > color > model > sku
    for (const level1 of mainProduct) {
      //   console.log('level1', level1)
      for (const level2 of level1?.product_group) {
        // console.log('level2', level2.color)
        for (const level3 of level2?.model) {
          //   console.log('level3', level3.model_name)
          for (const item of level3?.data) {
            // update status sku of product
            item.active = state.stocks?.[item.sku] ?? false
            // console.log('sku', item.sku, state.stocks?.[item.sku])

            // update status sku of bundle
            for (const bundle of item?.bundle_product) {
              bundle.active = state.stocks?.[bundle.sku] ?? false
            }

            // update status sku of bundle
            for (const variant of item?.variant_product) {
              variant.active = state.stocks?.[variant.sku] ?? false
            }
          }
        }
      }
    }

    for (const additional of additionalProducts) {
      for (const product of additional?.products) {
        product.active = state.stocks?.[product.sku] ?? false
      }
    }

    commit('setAdditionalProduct', additionalProducts)
    commit('setProducts', mainProduct)
  },

  getModel({ commit, state }, { slug, reload = false }) {
    if (state.products.length > 0 && reload === false) {
      return
    }

    let endpoint = `/v2/${campaignType}/products?campaign=${slug}&group_by=product-group-color`

    if (router.currentRoute.query.admin_quota) {
      let admin_quota =
        router.currentRoute.query.admin_quota.toLowerCase() === 'true'
          ? 1
          : 0
      endpoint = `${endpoint}&admin_quota=${admin_quota}`
    }

    commit('setLoading', true)
    return axios
      .get(endpoint)
      .then(response => {
        const mainProduct = response.data.data?.main_product

        // // response.data.data?.main_product?.sort(
        // //   (a, b) => {
        // //     return b.model[0].data[0].price - a.model[0].data[0].price
        // //   },
        // // )

        // model > color > sku
        // for (const product of mainProduct) {
        //   let hasForceBundle = false
        //   let sumForceBundle = 0
        //   for (const model of product?.model) {
        //     for (const sku of model?.data) {
        //       if (sku.force_bundle === 1) {
        //         hasForceBundle = true
        //         sumForceBundle =
        //           sku?.bundle_product?.reduce((acc, curr) => {
        //             if (curr.type !== 'warranty') {
        //               return +acc + +curr.price_ship
        //             }
        //             return +acc
        //           }, 0) ?? 0
        //         break
        //       }
        //     }

        //     if (hasForceBundle) {
        //       break
        //     }
        //   }

        //   if (
        //     hasForceBundle &&
        //     product.model?.[0]?.data?.[0]?.price + sumForceBundle <
        //       product.model?.[0]?.data?.[0]?.price
        //   ) {
        //     product.start_price =
        //       product.model?.[0]?.data?.[0]?.price + sumForceBundle
        //   } else {
        //     product.start_price = product.model?.[0]?.data?.[0]?.price
        //   }
        // }

        // product group > model > color > sku
        // for (const product of mainProduct) {
        //   let hasForceBundle = false
        //   let sumForceBundle = 0
        //   for (const group of product?.product_group) {
        //     for (const model of group?.model) {
        //       for (const sku of model?.data) {
        //         if (sku.force_bundle === 1) {
        //           hasForceBundle = true
        //           sumForceBundle =
        //             sku?.bundle_product?.reduce((acc, curr) => {
        //               if (curr.type !== 'warranty') {
        //                 return +acc + +curr.price_ship
        //               }
        //               return +acc
        //             }, 0) ?? 0
        //           break
        //         }
        //       }

        //       if (hasForceBundle) {
        //         break
        //       }
        //     }
        //   }

        //   if (
        //     hasForceBundle &&
        //     product.product_group?.[0]?.model?.[0]?.data?.[0]?.price +
        //       sumForceBundle <
        //       product.product_group?.[0]?.model?.[0]?.data?.[0]?.price
        //   ) {
        //     product.start_price =
        //       product.product_group?.[0]?.model?.[0]?.data?.[0]
        //         ?.price + sumForceBundle
        //   } else {
        //     product.start_price =
        //       product.product_group?.[0]?.model?.[0]?.data?.[0]?.price
        //   }
        // }

        // product group > color > model > sku

        for (const level1 of mainProduct) {
          //   console.log('level1', level1)
          let hasForceBundle = false
          let sumForceBundle = 0
          for (const level2 of level1?.product_group) {
            // console.log('level2', level2.color)
            for (const level3 of level2?.model) {
              //   console.log('level3', level3.model_name)
              for (const sku of level3?.data) {
                if (sku.force_bundle === 1) {
                  hasForceBundle = true
                  sumForceBundle =
                    sku?.bundle_product?.reduce((acc, curr) => {
                      if (curr.type !== 'warranty') {
                        return +acc + +curr.price_ship
                      }
                      return +acc
                    }, 0) ?? 0
                  break
                }
              }

              if (hasForceBundle) {
                break
              }
            }
          }

          if (
            hasForceBundle &&
            level1.product_group?.[0]?.model?.[0]?.data?.[0]?.price +
              sumForceBundle <
              level1.product_group?.[0]?.model?.[0]?.data?.[0]?.price
          ) {
            level1.start_price =
              level1.product_group?.[0]?.model?.[0]?.data?.[0]
            //     ?.price + sumForceBundle
          } else {
            level1.start_price =
              level1.product_group?.[0]?.model?.[0]?.data?.[0]?.price
          }
        }

        commit('setProducts', mainProduct)
        const additional_product = response.data.data?.additional_product.map(
          i => {
            return {
              ...i,
              products: i.products.map(item => {
                return {
                  ...item,
                  active: item?.active ?? false,
                }
              }),
            }
          },
        )
        commit('setAdditionalProduct', additional_product)

        commit('setIncoming', false)
        commit('setLoading', false)
      })
      .catch(err => {
        const data = err?.response?.data
        if (data?.message === 'incoming') {
          commit('setIncoming')
        } else {
          commit('setModelError')
        }
      })
  },

  selectModel({ commit }, model) {
    commit('setSelectModel', model)
    commit('setSelectColor', null)
    commit('setSelectSKU', null)
  },

  selectColor({ commit }, color) {
    commit('setSelectColor', color)
    commit('setSelectSKU', null)
  },

  selectSKU({ commit }, sku) {
    commit('setSelectSKU', sku)
  },

  selectLevel1({ commit, dispatch }, group) {
    commit('setSelectGroup', group)
    dispatch('autoSelectByLevel1')
  },

  selectLevel2({ commit, dispatch }, color) {
    commit('setSelectColor', color)
    dispatch('autoSelectByLevel2')
  },

  selectLevel3({ commit, dispatch }, model) {
    commit('setSelectModel', model)
    dispatch('autoSelectByLevel3')
  },

  selectLevel4({ commit }, sku) {
    commit('setSelectSKU', sku)

    // add SKU to query params
    if (sku.sku) {
      router.push({
        query: {
          ...router.currentRoute.query,
          sku: sku.sku,
        },
      })
    }
  },

  // Default
  autoSelect({ commit, state }) {
    const products = state?.products

    let activeSku = null

    for (const level1 of products) {
      for (const level2 of level1?.product_group) {
        for (const level3 of level2?.model) {
          const activeSku = level3?.data.find(sku => sku.active)
          if (activeSku) {
            // console.log('Active SKU found:')
            // Active SKU found:
            commit('setSelectGroup', level1)
            commit('setSelectColor', level2)
            commit('setSelectModel', level3)
            commit('setSelectSKU', activeSku)

            // add SKU to query params
            if (activeSku.sku) {
              router.push({
                query: {
                  ...router.currentRoute.query,
                  sku: activeSku.sku,
                },
              })
            }

            return
          }
        }
      }
    }

    // If no active SKU is found, select the first SKU
    if (!activeSku) {
      const firstProduct = products[0]
      const firstColor = firstProduct?.product_group[0]
      const firstModel = firstColor?.model[0]
      activeSku = firstModel?.data[0]

      commit('setSelectGroup', firstProduct)
      commit('setSelectColor', firstColor)
      commit('setSelectModel', firstModel)
      commit('setSelectSKU', activeSku)

      // add SKU to query params
      if (activeSku.sku) {
        router.push({
          query: {
            ...router.currentRoute.query,
            sku: activeSku.sku,
          },
        })
      }
    }
  },

  // Default for level 1
  autoSelectByLevel1({ commit, state }) {
    const level1 = state?.selectGroup.product_group

    let activeSku = null

    // console.log('level1', state?.selectGroup.name)

    for (const level2 of level1) {
      //   console.log('level2', level2.color)
      for (const level3 of level2?.model) {
        // console.log('level3', level2.code)
        const activeSku = level3?.data.find(sku => sku.active)
        if (activeSku) {
          //   console.log(
          //     'autoSelectByLevel1 : Active SKU found:',
          //     activeSku.name,
          //   )
          // Active SKU found:
          commit('setSelectColor', level2)
          commit('setSelectModel', level3)
          commit('setSelectSKU', activeSku)

          // add SKU to query params
          if (activeSku.sku) {
            router.push({
              query: {
                ...router.currentRoute.query,
                sku: activeSku.sku,
              },
            })
          }
          return
        }
      }
    }

    // If no active SKU is found, select the first SKU
    if (!activeSku) {
      const firstColor = level1[0]
      const firstModel = firstColor?.model[0]
      activeSku = firstModel?.data[0]

      //   console.log(
      //     'No active SKU found. Selecting first SKU:',
      //     activeSku,
      //   )

      commit('setSelectColor', firstColor)
      commit('setSelectModel', firstModel)
      commit('setSelectSKU', activeSku)

      // add SKU to query params
      if (activeSku.sku) {
        router.push({
          query: {
            ...router.currentRoute.query,
            sku: activeSku.sku,
          },
        })
      }
    }
  },

  // Default for level 2
  autoSelectByLevel2({ commit, state }) {
    const level2 = state?.selectColor?.model

    let activeSku = null

    for (const level3 of level2) {
      const activeSku = level3?.data.find(sku => sku.active)
      if (activeSku) {
        // console.log('Active SKU found:', activeSku.name)
        // Active SKU found:
        commit('setSelectModel', level3)
        commit('setSelectSKU', activeSku)

        // add SKU to query params
        if (activeSku.sku) {
          router.push({
            query: {
              ...router.currentRoute.query,
              sku: activeSku.sku,
            },
          })
        }

        return
      }
    }

    // If no active SKU is found, select the first SKU
    if (!activeSku) {
      const firstModel = level2[0]
      activeSku = firstModel?.data[0]
      //   console.log(
      //     'No active SKU found. Selecting first SKU:',
      //     activeSku,
      //   )

      commit('setSelectModel', firstModel)
      commit('setSelectSKU', activeSku)

      // add SKU to query params
      if (activeSku.sku) {
        router.push({
          query: {
            ...router.currentRoute.query,
            sku: activeSku.sku,
          },
        })
      }
    }
  },

  autoSelectByLevel3({ commit, state }) {
    const level3 = state?.selectModel?.data

    let activeSku = null

    for (const sku of level3) {
      if (sku.active) {
        activeSku = sku
        // console.log('Active SKU found:', activeSku.sku)
        // add SKU to query params
        if (activeSku.sku) {
          router.push({
            query: {
              ...router.currentRoute.query,
              sku: activeSku.sku,
            },
          })
        }
        break
      }
    }

    // If no active SKU is found, select the first SKU
    if (!activeSku) {
      activeSku = level3[0]
      //   console.log(
      //     'No active SKU found. Selecting first SKU:',
      //     activeSku,
      //   )

      // add SKU to query params
      if (activeSku.sku) {
        router.push({
          query: {
            ...router.currentRoute.query,
            sku: activeSku.sku,
          },
        })
      }
    }

    commit('setSelectSKU', activeSku)
  },

  autoSelectBySKU({ commit, state, dispatch }, sku) {
    const products = state?.products

    for (const level1 of products) {
      for (const level2 of level1?.product_group) {
        for (const level3 of level2?.model) {
          const activeSku = level3?.data.find(
            item => item.sku === sku,
          )
          if (activeSku) {
            // console.log('autoSelectBySKU:', activeSku.name)
            // Active SKU found:
            commit('setSelectGroup', level1)
            commit('setSelectColor', level2)
            commit('setSelectModel', level3)
            commit('setSelectSKU', activeSku)

            // add SKU to query params
            if (activeSku.sku) {
              router.push({
                query: {
                  ...router.currentRoute.query,
                  sku: activeSku.sku,
                },
              })
            }
            return
          }
        }
      }
    }

    // If no active SKU is found, select the first SKU
    if (router.currentRoute.query.code) {
      router.push({ name: 'Error', params: { error: 'notfound' } })
    } else {
      router.push({
        query: { ...router.currentRoute.query, sku: undefined },
      })
      dispatch('autoSelect')
    }
  },

  reset({ getters, dispatch }) {
    const products = getters?.getModels
    if (products?.length === 0) {
      return false
    }
    // const active = state?.products[0]?.model[0]?.data?.filter(
    //   items => {
    //     return items.active
    //   },
    // )
    // const result = active.length > 0 ? active[0] : null

    dispatch('autoSelect')

    return true
  },
}

// mutations
const mutations = {
  setStocks(state, payload) {
    state.stocks = payload
  },

  setSelectGroup(state, group) {
    state.selectGroup = group
  },

  setSelectModel(state, model) {
    state.selectModel = model
  },

  setSelectColor(state, color) {
    state.selectColor = color
  },

  setSelectSKU(state, sku) {
    state.selectSKU = sku
  },

  setLoading(state, val) {
    state.isLoading = val
    state.isError = false
  },

  setModelError(state) {
    state.isLoading = false
    state.isError = true
  },

  setIncoming(state, val = true) {
    state.isLoading = false
    state.isIncoming = val
  },

  setProducts(state, products) {
    const $tocode = val => {
      return val
        .toLowerCase()
        .split(' ')
        .join('-')
    }

    /**
     * create code from product name / color
     */

    // eslint-disable-next-line no-unused-vars
    // group > model > color > sku
    // const results = products.map(p => {
    //   p.code = $tocode(p.name)

    //   p.product_group = p.product_group.map(g => {
    //     g.code = $tocode(g.model_name)

    //     g.model.map(c => {
    //       c.code = $tocode(c.color)

    //       c.data.map(sku => {
    //         sku.product_group = $tocode(p.name)
    //         // TODO: Remove
    //         // sku.active = false

    //         return sku
    //       })

    //       return c
    //     })

    //     return g
    //   })

    //   return p
    // })

    // group > color > model > sku
    const results = products.map(p => {
      p.code = $tocode(p.name)

      p.product_group = p.product_group.map(g => {
        g.code = $tocode(g.color)

        g.model.map(c => {
          c.code = $tocode(c.model_name)
          c.name = c.model_name

          c.data.map(sku => {
            sku.product_group = $tocode(p.name)
            // TODO: Remove
            // sku.active = false

            return sku
          })

          return c
        })

        return g
      })

      return p
    })

    state.products = results
    state.isLoaded = true
  },

  setAdditionalProduct(state, products) {
    state.additionalProduct = products
    state.isLoaded = true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
