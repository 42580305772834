import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'
Vue.use(VueRouter)

// const Load = () => import('../views/Load.vue')
// const Home = () => import('../views/HomeIpad.vue')
// const Shop = () => import('@/views/Shop.vue')
// const Bundle = () => import('@/views/Bundle.vue')
// const Order = () => import('@/views/Order.vue')
const OrderLists = () => import('@/views/OrderLists.vue')

const Success = () => import('@/views/Success.vue')
const NoCampaign = () => import('@/views/NoCampaign.vue')
const PlayStation = () => import('@/views/PlayStation.vue')
const Incoming = () => import('@/views/Incoming.vue')
const ProductDetail = () => import('@/views/ProductDetail.vue')
const Swiper = () => import('@/views/Swiper.vue')
const CustomerAlsoBought = () =>
  import('@/views/CustomerAlsoBought.vue')
const Cart = () => import('@/views/Cart.vue')
const PreRegister = () => import('@/views/PreRegister.vue')
const CountDown = () => import('@/views/CountDown.vue')
const CampaignList = () => import('@/views/CampaignList.vue')
const TradeIn = () => import('@/views/TradeIn.vue')
const Remaining = () => import('@/views/Remaining.vue')
const Static = () => import('@/views/Static.vue')
const StockList = () => import('@/views/StockList.vue')
const ProductFeed = () => import('@/views/ProductFeed.vue')
const ConfirmPayment = () => import('@/views/ConfirmPayment.vue')

const guard = async (to, _from, next) => {
  // Check if the user is logged in
  const token = Vue.prototype.$cookies.get('token') ?? null

  // Save the current page to redirect after login
  localStorage.setItem('redirectPage', to.fullPath)

  if (token) {
    localStorage.removeItem('redirectPage')
    next()
  } else {
    await Store.dispatch('auth/logout')

    // Redirect to login and add slug query params
    next({
      name: 'Login',
      query: { campaign: to.params.slug },
    })
  }
}

const routes = [
  {
    path: '/',
    name: 'Campaign',
    component: CampaignList,
  },
  {
    path: '/ps5',
    name: 'PS5',
    component: PlayStation,
  },
  {
    path: '/incoming',
    name: 'Incoming',
    component: Incoming,
  },
  {
    path: '/:slug/shop',
    name: 'ProductDetail',
    component: ProductDetail,
  },
  {
    path: '/:slug/order',
    name: 'CustomerAlsoBought',
    component: CustomerAlsoBought,
  },
  {
    path: '/:slug/cart',
    name: 'Cart',
    component: Cart,
  },
  {
    path: '/:slug/pre-register',
    name: 'PreRegister',
    component: PreRegister,
  },
  {
    path: '/:slug/trade-in',
    name: 'TradeIn',
    component: TradeIn,
  },
  {
    path: '/remaining-amount/:orderId',
    name: 'Remaining',
    component: Remaining,
  },
  {
    path: '/order-detail/:orderId',
    name: 'OrderDetail',
    component: Remaining,
  },
  {
    path: '/:slug/countdown',
    name: 'CountDown',
    component: CountDown,
  },
  {
    path: '/list',
    name: 'CampaignList',
    component: CampaignList,
  },
  {
    path: '/swiper',
    name: 'Swiper',
    component: Swiper,
  },
  {
    path: '/product-feed',
    name: 'ProductFeed',
    component: ProductFeed,
  },
  // {
  //   path: '/:slug/shop',
  //   name: 'Shop',
  //   component: Shop,
  // },
  // {
  //   path: '/:slug/bundle',
  //   name: 'Bundle',
  //   component: Bundle,
  // },
  // {
  //   path: '/:slug/order',
  //   name: 'Order',
  //   component: Order,
  // },
  {
    path: '/order-lists',
    name: 'Order Lists',
    component: OrderLists,
  },
  {
    path: '/static/page/:slug',
    name: 'StaticPage',
    component: Static,
  },
  {
    path: '/thank/:uid',
    name: 'Thank',
    component: Success,
  },
  {
    path: '/success/:uid',
    name: 'Success',
    component: Success,
  },
  {
    path: '/no-campaign',
    name: 'NoCampaign',
    component: NoCampaign,
  },
  {
    path: '/confirm-payment/:uid',
    name: 'ConfirmPayment',
    component: ConfirmPayment,
    beforeEnter: guard,
  },
  {
    path: '/:slug/pre-register-customer-information',
    name: 'CustomerInformation',
    component: () => import('@/views/CustomerInformation.vue'),
  },
  // {
  //   path: '/stock/list',
  //   name: 'StockList',
  //   component: StockList,
  // },
  {
    path: '/stock/live/:slug',
    name: 'StockList',
    component: StockList,
    beforeEnter: guard,
  },
  {
    path: '/error/:error',
    name: 'Error',
    component: NoCampaign,
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/views/404.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  const excludeRoutes = ['Thank', 'ConfirmPayment', 'StockList']

  if (
    !hasQueryParams(to) &&
    hasQueryParams(from) &&
    !excludeRoutes.includes(to.name)
  ) {
    next({
      name: to.name,
      params: to.params,
      query: from.query,
    })
  } else {
    next()
  }
})

export default router
